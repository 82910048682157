

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Raleway/Raleway-VariableFont_wght.ttf');
}




body {
  margin: 0;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
